export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: 'OOOPS !',
  subheader:
    'Il semblerait que la page ou le produit que vous cherchez soit introuvable. Toutes nos excuses pour la gêne occasionnée. Nous vous invitons à effectuer une nouvelle recherche.',
  buttonText: 'Retour au site',
}
